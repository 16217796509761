import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

// Joy UI
import { CssVarsProvider } from '@mui/joy/styles';

// Local
import { HomePage } from './pages/HomePage';
import { SessionPage } from './pages/SessionPage';
import { ContactPage } from './pages/ContactPage';

function App() {
  return (
    <CssVarsProvider>
      <HashRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route exaxt path="/session" element={<SessionPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
        </Routes>
      </HashRouter>
    </CssVarsProvider>
  );
}

export default App;
