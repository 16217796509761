import React from 'react';

// Joy UI
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Styles
import './Back.css';

export function Back() {
  return (
    <Box className="back-button">
      <Link href={'/#/'} underline="none">
        <IconButton variant="outlined">
          <ArrowBackIcon />
        </IconButton>
      </Link>
    </Box>
  );
}
