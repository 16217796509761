import React from 'react';

// Joy UI
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';

// Local
import { Back } from '../components/Back';

// Styles
import '../styles/Page.css';
import '../styles/Form.css';

export function ContactPage(props) {
  return (
    <Box className="page">
      <Card className="form-card">
        <h2>Contact</h2>
        <form method="POST" name="contactForm" className="contactForm">
          <input type="hidden" name="form-name" value="contactForm" />
          <Input
            className="form-element"
            type="email"
            name="email"
            placeholder="Email"
            required
          />
          <Textarea
            className="form-element-long"
            name="message"
            placeholder="Type your message..."
            required
            minRows={4}
          />
          <Button type="submit">Submit</Button>
        </form>
      </Card>
      <Back />
    </Box>
  );
}
