import React from 'react';

// Joy UI
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';

// Styles
import './Tile.css';

export function Tile(props) {
  const { title, icon, url, external } = props;

  return (
    <Grid className="tile-grid" item xs={12} md={4} key={title}>
      <Link
        className="tile-link"
        href={url}
        underline="none"
        target={external && '_blank'}
        rel={external && 'noopener noreferrer'}
      >
        <Card
          className="tile-card"
          sx={{
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: 'lg',
            },
            cursor: 'pointer',
          }}
        >
          <Typography level="h4">{title}</Typography>
          <Box className="tile-card-icon">{icon}</Box>
        </Card>
      </Link>
    </Grid>
  );
}
