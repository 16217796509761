import React from 'react';

// Joy UI
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { Grid } from '@mui/joy';

// Icons
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import EmailIcon from '@mui/icons-material/Email';

// Local
import { Tile } from '../components/Tile';

// Assets
import LogoSignature from '../assets/logo.png';
import BannerImg from '../assets/banner.jpeg';
import YoutubeImg from '../assets/youtube.png';

// Styles
import '../styles/Page.css';
import './HomePage.css';

export function HomePage() {
  return (
    <Box className="page">
      <img className="app-logo" width={100} src={LogoSignature} alt="logo" />
      <img className="app-banner" height={120} src={BannerImg} alt="banner" />
      <Box className="app-subscribe-button">
        <Button
          variant="solid"
          color="neutral"
          component="a"
          href={process.env.REACT_APP_YOUTUBE_CHANNEL_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="youtube-logo"
            src={YoutubeImg}
            height={20}
            alt="youtube-logo"
          />
          <div>Subscribe</div>
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Tile
          title="Session Drumming"
          icon={<GraphicEqIcon fontSize="xl4" />}
          url="/#/session"
        />
        <Tile
          title="YouTube Channel"
          icon={<OndemandVideoIcon fontSize="xl4" />}
          url={process.env.REACT_APP_YOUTUBE_CHANNEL_URL}
          external={true}
        />
        <Tile
          title="Contact Me"
          icon={<EmailIcon fontSize="xl4" />}
          url="/#/contact"
        />
      </Grid>
    </Box>
  );
}
