import React from 'react';

// Joy UI
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Button from '@mui/joy/Button';

// Local
import { Back } from '../components/Back';

// Assets
import SessionImg from '../assets/session.jpeg';

// Styles
import '../styles/Page.css';
import '../styles/Form.css';

export function SessionPage(props) {
  return (
    <Box className="page">
      <Card className="form-card">
        <h2>Session Drumming</h2>
        <img src={SessionImg} alt="session" />
        <p>Need to add drums to your songs?</p>
        <p>
          My services as a session drummer:
          <li>
            <b>compose</b> and/or <b>arrange</b> the drum parts, as needed
          </li>
          <li>
            <b>perform</b> and <b>record</b> the drum parts in my recording
            studio
          </li>
          <li>
            <b>edit</b> the recordings, as needed
          </li>
        </p>
        <p>
          You will get <b>high-quality drums stems</b> in WAV format.
        </p>
        <p>Let's talk about your project!</p>
        <form method="POST" name="sessionForm" className="sessionForm">
          <input type="hidden" name="form-name" value="sessionForm" />
          <Input
            className="form-element"
            type="email"
            name="email"
            placeholder="Your email"
            required
          />
          <Textarea
            className="form-element-long"
            name="description"
            placeholder="Brief description of your project..."
            required
            minRows={4}
          />
          <Button type="submit">Submit</Button>
        </form>
      </Card>
      <Back />
    </Box>
  );
}
